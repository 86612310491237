<template>
  <div>
    <b-modal modal-class="traffic_routing_model " ref="customize_link_preview_v2"
             id="customizeLinkPreviewV2" no-close-on-backdrop
             hide-footer hide-header>

      <div class="flex pt-[0.75rem] justify-between">
        <p class="text-[0.875rem] leading-[0.938rem] font-poppins text-[#3C4549] font-bold">Customize Link Preview</p>
        <div @click="$bvModal.hide('customizeLinkPreviewV2')" class="float-right w-6 h-6 flex justify-end">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
          </svg>
        </div>
      </div>

      <div class="w-full mt-4">
        <img :src="getSaveLink.seo.image" />
        <p class="mt-4 text font-bold !text-[#3C4549]">{{getSaveLink.seo.title}}</p>
        <p class="text">{{getSaveLink.seo.description}}</p>
      </div>

      <div class="text-center w-full pt-10 pb-11">
        <Button
          id="login-button"
          type="button"
          class="border border-gray-500 hover:bg-[#F2F3F8]"
          buttonClass="btn-lg"
          @click="$bvModal.hide('customizeLinkPreviewV2')"
        >
          <template v-slot:label>Close</template>
        </Button>
      </div>

    </b-modal>
    <div :class="{'opacity-30': !getSaveLink.showSeo}" class="h-full" v-if="toggle">
      <div class="flex justify-between">
        <p class="text !text-[#202324] font-bold">Customize Link Preview</p>
        <button type="button" :class="{'cursor-not-allowed': !getSaveLink.showSeo}" :disabled="!getSaveLink.showSeo" v-if="!getLinksLoaders.customizeLinkPreviewLoader" @click="$bvModal.show('customizeLinkPreviewV2')" class="text !text-[#202324] flex gap-x-1 items-center">
          Preview
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 16" fill="none">
            <g clip-path="url(#clip0_2209_4968)">
              <path d="M0.800049 7.99935C0.800049 7.99935 4.00005 2.66602 9.60005 2.66602C15.2 2.66602 18.4 7.99935 18.4 7.99935C18.4 7.99935 15.2 13.3327 9.60005 13.3327C4.00005 13.3327 0.800049 7.99935 0.800049 7.99935Z" stroke="#2560D7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.59995 10C10.9254 10 12 9.10457 12 8C12 6.89543 10.9254 6 9.59995 6C8.27447 6 7.19995 6.89543 7.19995 8C7.19995 9.10457 8.27447 10 9.59995 10Z" stroke="#2560D7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_2209_4968">
                <rect width="19.2" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div v-if="getLinksLoaders.customizeLinkPreviewLoader" class="mt-5  w-full h-full flex flex-1 flex-col justify-center items-center">
        <Loader color="text-[#2560d7]"></Loader>
        <p class="text mt-[0.4rem]">Loading...</p>
      </div>
      <div v-else>
        <FloatingLabelInput
          id="seo_title"
          v-model="getSaveLink.seo.title"
          type="text"
          label="Meta Title"
          :disabled="!getSaveLink.showSeo"
          customClass="mt-6 h-[3.5rem] bg-[#F4F6FA]"
          :errorType="customizeLinkPreviewValidations.title || customizeLinkPreviewValidations.titleLength  ? 'danger' : ''"
        >
          <template v-slot:error_message>
            <InputFieldMessage v-if="customizeLinkPreviewValidations.title" :message="customizeLinkPreviewMessages.title"></InputFieldMessage>
            <InputFieldMessage v-else-if="customizeLinkPreviewValidations.titleLength" :message="customizeLinkPreviewMessages.titleLength"></InputFieldMessage>
          </template>
        </FloatingLabelInput>
        <FloatingLabelInput
          id="seo_description"
          v-model="getSaveLink.seo.description"
          type="text"
          label="Meta Description"
          :disabled="!getSaveLink.showSeo"
          customClass="mt-[0.75rem] h-[3.5rem] bg-[#F4F6FA]"
          :errorType="customizeLinkPreviewValidations.description || customizeLinkPreviewValidations.descriptionLength  ? 'danger' : ''"
        >
          <template v-slot:error_message>
            <InputFieldMessage v-if="customizeLinkPreviewValidations.description" :message="customizeLinkPreviewMessages.description"></InputFieldMessage>
            <InputFieldMessage v-else-if="customizeLinkPreviewValidations.descriptionLength" :message="customizeLinkPreviewMessages.descriptionLength"></InputFieldMessage>
          </template>
        </FloatingLabelInput>
        <URLInputFloatingV2
          class="mt-[0.75rem]"
          id="meta_image_url"
          v-model="getSaveLink.seo.image"
          :validations="URLValidations"
          type="text"
          :disabled="!getSaveLink.showSeo"
          label="Meta Image URl"
          customClass="h-[3.5rem] bg-[#F4F6FA]"
          :errorType="customizeLinkPreviewValidations.url || customizeLinkPreviewValidations.urlLength || customizeLinkPreviewValidations.urlValid  ? 'danger' : ''"
        >
          <template v-slot:error_message>
            <InputFieldMessage v-if="customizeLinkPreviewValidations.url" :message="customizeLinkPreviewMessages.url"></InputFieldMessage>
            <InputFieldMessage v-else-if="customizeLinkPreviewValidations.urlLength" :message="customizeLinkPreviewMessages.urlLength"></InputFieldMessage>
            <InputFieldMessage v-else-if="customizeLinkPreviewValidations.urlValid" :message="customizeLinkPreviewMessages.urlValid"></InputFieldMessage>
          </template>
        </URLInputFloatingV2>
        <ImageUploadV2
          customClass="mt-[0.75rem] h-32"
          :image="getSaveLink.seo.image"
          :disabled="!getSaveLink.showSeo"
          @click="getSaveLink.seo.image = $event"
        ></ImageUploadV2>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  customizeLinkPreviewMessages,
  customizeLinkPreviewValidations,
  URLMessages,
  URLValidations
} from '@/common/attributes'

export default {
  props: ['toggle'],
  computed: {
    ...mapGetters([
      'getSaveLink',
      'getLoaders',
      'getLinksLoaders'
    ])
  },
  data() {
    return {
      customizeLinkPreviewValidations: customizeLinkPreviewValidations,
      customizeLinkPreviewMessages: customizeLinkPreviewMessages,
      URLValidations: URLValidations,
      URLMessages: URLMessages
    }
  },
  created () {
    this.customizeLinkPreviewValidations = {...customizeLinkPreviewValidations, ...URLValidations}
    this.customizeLinkPreviewMessages = {...customizeLinkPreviewMessages, ...URLMessages}
  },
  components: {
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    ImageUploadV2: () => import('@/ui/ui-kit/v2/ImageUploadV2.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue')
  },
  methods: {
    validateMeta() {
      let customizeLinkPreviewValidate = false
      if (this.getSaveLink.showSeo) {
        this.customizeLinkPreviewValidations.title = this.requiredCheck(this.getSaveLink.seo.title)
        this.customizeLinkPreviewValidations.titleLength = !this.maxLength(this.getSaveLink.seo.title, 500)
        this.customizeLinkPreviewValidations.description = this.requiredCheck(this.getSaveLink.seo.description)
        this.customizeLinkPreviewValidations.descriptionLength = !this.maxLength(this.getSaveLink.seo.description, 500)
        this.customizeLinkPreviewValidations.url = this.requiredCheck(this.getSaveLink.seo.image)
        this.customizeLinkPreviewValidations.urlLength = !this.maxLength(this.getSaveLink.seo.image, 500)

        if(this.getSaveLink.seo.image && this.getSaveLink.seo.image.length) {
          this.getSaveLink.seo.image = this.urlConcatenation(this.getSaveLink.seo.image)
          this.customizeLinkPreviewValidations.urlValid = !this.isValidURL(this.getSaveLink.seo.image)
        }

        customizeLinkPreviewValidate = Object.keys(this.customizeLinkPreviewValidations).every(k => this.customizeLinkPreviewValidations[k] == false)
      }
      return customizeLinkPreviewValidate
    }
  },
  watch: {
    'getSaveLink.seo.title' (value) {
      this.customizeLinkPreviewValidations.title = this.requiredCheck(value)
      this.customizeLinkPreviewValidations.titleLength = !this.maxLength(value, 500)
    },
    'getSaveLink.seo.description' (value) {
      this.customizeLinkPreviewValidations.description = this.requiredCheck(value)
      this.customizeLinkPreviewValidations.descriptionLength = !this.maxLength(value, 500)
    },
    'getSaveLink.seo.image' (value) {
      if(value && value.length > 8) {
        this.customizeLinkPreviewValidations.url = this.requiredCheck(value)
        this.customizeLinkPreviewValidations.urlLength = !this.maxLength(value, 500)
        this.getSaveLink.seo.image = this.urlConcatenation(this.getSaveLink.seo.image)
        this.customizeLinkPreviewValidations.urlValid = !this.isValidURL(this.getSaveLink.seo.image)
      }
    },
  }
}
</script>
